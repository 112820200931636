import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { readLomavisCreatorConfig } from '../lomavisCreator/lomavisCreatorConfig';
import { readExternalContentConfig } from '../externalContent/externalContentConfig';
import {
  PLACEHOLDER,
  SQUARE_LOGO
} from '../../../components/brandOnboarding/constants';
import { WebFont } from '../../../components/generic/fontSelector';
import { saveLocationBrandingSettingsOnBackend } from '../branding/utils';

const savedLomavisCreatorConfig = readLomavisCreatorConfig();
const savedExternalContentConfig = readExternalContentConfig();

console.log('configs', savedExternalContentConfig, savedLomavisCreatorConfig);

const initialState = {
  lomavisCreatorConfig: readLomavisCreatorConfig(),
  externalContentConfig: readExternalContentConfig(),
  locationUuid: savedLomavisCreatorConfig?.location?.uuid,
  cloudUuid: savedExternalContentConfig?.uuid,
  brandHomePage: '',
  logoLoadUrls: [
    `${window.location.origin}/images/redirect-to-external-image/?url=${PLACEHOLDER}`,
    `https://cdn.brandfetch.io/${PLACEHOLDER}/w/400/h/400?c=1idRdC_0swarjJXB4Ig`,
    `https://cdn.brandfetch.io/${PLACEHOLDER}/w/512/h/154/logo?c=1idRdC_0swarjJXB4Ig`,
    `https://cdn.brandfetch.io/${PLACEHOLDER}/w/512/h/154/theme/light/logo?c=1idRdC_0swarjJXB4Ig`,
    `https://cdn.brandfetch.io/${PLACEHOLDER}/w/512/h/512/symbol?c=1idRdC_0swarjJXB4Ig`
  ],

  loadedLogos: [],
  squareLogos: [],
  rectangleLogos: [],
  customSquareLogo: null,
  customRectangleLogo: null,
  selectedLogos: {
    square: '',
    rectangle: ''
  },
  logoBackGroundColor: '',
  brandColors: {
    primaryColor: '',
    secondaryColor: ''
  },
  selectedFontFamily: {},
  submittingInfo: false,
  onError: ''
};

const brandOnboardingSlice = createSlice({
  name: 'brandOnboarding',
  initialState,
  reducers: {
    updateBrandHomePage: (
      state,
      action: PayloadAction<{
        url: string;
      }>
    ) => {
      state.brandHomePage = action.payload.url;
    },
    updateLoadedLogos: (
      state,
      action: PayloadAction<{
        url: string;
      }>
    ) => {
      state.loadedLogos = [...state.loadedLogos, action.payload.url];
    },

    deleteLoadedLogo: (
      state,
      action: PayloadAction<{
        url: string;
      }>
    ) => {
      const filteredLogos = state.loadedLogos?.filter(
        (item) => item !== action.payload.url
      );
      state.loadedLogos = filteredLogos;
    },

    updateLogoWithType: (
      state,
      action: PayloadAction<{
        url: string;
        type: string;
      }>
    ) => {
      if (action.payload.type === SQUARE_LOGO) {
        state.squareLogos = [...state.squareLogos, action.payload.url];
      } else {
        state.rectangleLogos = [...state.rectangleLogos, action.payload.url];
      }
    },
    deleteLogoWithType: (
      state,
      action: PayloadAction<{
        url: string;
        type: string;
      }>
    ) => {
      console.log('delete trigger');
      if (action.payload.type === SQUARE_LOGO) {
        const filteredLogos = state.squareLogos?.filter(
          (item) => item !== action.payload.url
        );
        state.squareLogos = filteredLogos;
      } else {
        const filteredLogos = state.rectangleLogos?.filter(
          (item) => item !== action.payload.url
        );
        state.rectangleLogos = filteredLogos;
      }
    },

    selectALogo: (
      state,
      action: PayloadAction<{
        url: string;
        type: string;
      }>
    ) => {
      if (state.selectedLogos[action.payload.type] == action.payload.url) {
        state.selectedLogos[action.payload.type] = '';
        return;
      }
      state.selectedLogos[action.payload.type] = action.payload.url;
    },

    updateLogoBgColor: (
      state,
      action: PayloadAction<{
        color: string;
      }>
    ) => {
      const formattedValue = `#${action.payload.color.replace(/^#+/, '')}`;
      state.logoBackGroundColor = formattedValue;
    },

    updateBrandColors: (
      state,
      action: PayloadAction<{
        type: string;
        color: string;
      }>
    ) => {
      const formattedValue = `#${action.payload.color.replace(/^#+/, '')}`;
      state.brandColors[action.payload.type] = formattedValue;
    },
    updateSelectedFont: (state, action: PayloadAction<{ font: WebFont }>) => {
      state.selectedFontFamily = action.payload.font;
    },
    updateCustomSquareLogo: (
      state,
      action: PayloadAction<{ logoObj: any }>
    ) => {
      state.customSquareLogo = action.payload.logoObj;
    },
    updateCustomRectangleLogo: (
      state,
      action: PayloadAction<{ logoObj: any }>
    ) => {
      state.customRectangleLogo = action.payload.logoObj;
    },
    setSubmittingInfo: (state, action: PayloadAction<{ status: boolean }>) => {
      state.submittingInfo = action.payload.status;
    },
    setError: (state, action: PayloadAction<{ msg: any }>) => {
      state.onError = action.payload.msg;
    }
  }
});

export const {
  updateBrandHomePage,
  updateLoadedLogos,
  updateLogoWithType,
  deleteLoadedLogo,
  deleteLogoWithType,
  selectALogo,
  updateBrandColors,
  updateLogoBgColor,
  updateSelectedFont,
  updateCustomSquareLogo,
  setSubmittingInfo,
  setError,
  updateCustomRectangleLogo
} = brandOnboardingSlice.actions;
export default brandOnboardingSlice.reducer;

export const saveBrandingInformation = (
  data: any,
  successCallback: any,
  errorCallback: any,
  reset: boolean = false
) => {
  return async (dispatch: any) => {
    try {
      dispatch(
        brandOnboardingSlice.actions.setSubmittingInfo({ status: true })
      );
      try {
        await saveLocationBrandingSettingsOnBackend(data, reset);
        successCallback();
      } catch (e) {
        errorCallback(e);
      }
    } catch (error: any) {
      dispatch(brandOnboardingSlice.actions.setError({ msg: error.message }));
      throw error;
    } finally {
      dispatch(
        brandOnboardingSlice.actions.setSubmittingInfo({ status: false })
      );
    }
  };
};
