import { combineReducers } from 'redux';
// slices
import analyticsAggregatedPeriodicalDataReducer from './slices/analytics/analyticsAggregatedPeriodicalData';
import topPosts from './slices/analytics/topRatedPosts';
import tablePosts from './slices/analytics/datatable';
import postsList from './slices/analytics/postsList';
import locationDetails from './slices/location/locationDetails';
import userDetails from './slices/user/userDetails';
import shortAnalytics from './slices/analytics/shortAnalytics';
import teamManagement from './slices/accounts/team/team';
import invites from './slices/accounts/invites/invites';
import locationsList from './slices/location/locationsList';
import cloudsList from './slices/cloud/cloudsList';
import subscriptionManagement from './slices/subscriptionManagement/subscriptionManagement';
import lomavisCreator from './slices/lomavisCreator/lomavisCreator';
import communication from './slices/communication';
import modalStateSlice from './slices/modals/modalStates';
import externalContent from './slices/externalContent/externalContent';
import postIdeas from './slices/postIdeas';
import brandOnboarding from './slices/brandOnboarding/brandOnboarding';
import branding from './slices/branding';
import WhatsappTemplateBuilder from './slices/whatsappTemplate';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  analyticsAggregatedPeriodicalData: analyticsAggregatedPeriodicalDataReducer,
  topPosts,
  tablePosts,
  postsList,
  locationDetails,
  userDetails,
  shortAnalytics,
  team: teamManagement,
  invites,
  locationsList,
  cloudsList,
  subscriptionManagement,
  lomavisCreator,
  communication,
  modalStateSlice,
  externalContent,
  brandOnboarding,
  postIdeas,
  branding,
  WhatsappTemplateBuilder
});

export default rootReducer;
