/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  loadLocationBrandingSettingsFromBackend,
  saveLocationBrandingSettingsOnBackend
} from './utils';

interface BrandingData {
  selectedFont: any | null;
  primaryColor: string;
  secondaryColor: string;
  squareLogo: any | null;
  rectangleLogo: any | null;
}

interface BrandingSettingsState {
  data: BrandingData;
  isLoading: boolean;
  error: string | null;
}

const initialDataState: BrandingData = {
  selectedFont: null,
  primaryColor: '',
  secondaryColor: '',
  squareLogo: null,
  rectangleLogo: null
};

const initialState: BrandingSettingsState = {
  data: initialDataState,
  isLoading: false,
  error: null
};

const brandingSlice = createSlice({
  name: 'brandingSettings',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setBrandingData: (state, action: PayloadAction<BrandingData>) => {
      state.data = action.payload;
      state.error = null;
    },
    updateBrandingData: (
      state,
      action: PayloadAction<Partial<BrandingData>>
    ) => {
      state.data = { ...state.data, ...action.payload };
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetBranding: (state) => {
      state.data = initialDataState;
      state.error = null;
      state.isLoading = false;
    }
  }
});

export default brandingSlice.reducer;

export const { setBrandingData, updateBrandingData, resetBranding } =
  brandingSlice.actions;

export const fetchBrandingSettings = (locationUUID: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(brandingSlice.actions.startLoading());
      const brandingData = await loadLocationBrandingSettingsFromBackend(
        locationUUID
      );
      dispatch(brandingSlice.actions.setBrandingData(brandingData));
    } catch (error: any) {
      dispatch(brandingSlice.actions.setError(error.message));
    } finally {
      dispatch(brandingSlice.actions.stopLoading());
    }
  };
};

export const saveBrandingSettings = (
  data: any,
  successCallback: any,
  errorCallback: any,
  reset: boolean = false
) => {
  return async (dispatch: any) => {
    try {
      dispatch(brandingSlice.actions.startLoading());
      try {
        await saveLocationBrandingSettingsOnBackend(data, reset);
        successCallback();
      } catch (e) {
        errorCallback(e);
      }
    } catch (error: any) {
      dispatch(brandingSlice.actions.setError(error.message));
      throw error;
    } finally {
      dispatch(brandingSlice.actions.stopLoading());
    }
  };
};
