import { resolve } from '../../../api/endPoints';
import axiosClient from '../../../utils/axios';
import webfonts from '../../../pages/settings/branding/template_branding/webfonts.json'; // Import the JSON file directly
export const saveLocationBrandingSettingsOnBackend = (data: any, reset=false): any => {
  const url = resolve(
    'location_branding_settings',
    {
      locationUUID: data.uuid
    },
    {}
  );
  const payload: any = {
    fontFamily: data.fontFamily,
    template_branding_primary_color: data.primary,
    template_branding_secondary_color: data.secondary,
    template_logo_square_uuid: data.squareLogo,
    template_logo_rectangle_uuid: data.rectangleLogo
  };
  if (reset) {
    payload.template_logo_square = null;
    payload.template_logo_rectangle = null;
  }
  const result = axiosClient.patch(url, payload);
  return result;
};

export const loadLocationBrandingSettingsFromBackend = async (locationUUID) => {
  // Construct the API URL (assumes 'location_branding_settings' is the correct endpoint)
  const url = resolve('location_branding_settings', { locationUUID }, {});
  const { data } = await axiosClient.get(url);

  // Transform API response into the shape your component expects.
  // This shape can vary depending on how you want to initialize your state.
  let font = null;
  if (data.fontFamily) {
    font = webfonts.items.find((item: any) => item.family == data.fontFamily);
  }
  return {
    selectedFont: font || null,
    primaryColor: data.template_branding_primary_color || '',
    secondaryColor: data.template_branding_secondary_color || '',
    // Provide "squareLogo" and "rectangleLogo" in a shape
    // that your FilePondUploader or other code can work with.
    // If you want to store them as { url, ... } or something else, adjust accordingly.
    squareLogo: data.template_logo_square
      ? { url: data.template_logo_square }
      : null,
    rectangleLogo: data.template_logo_rectangle
      ? { url: data.template_logo_rectangle }
      : null
  };
};
